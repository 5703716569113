import React from 'react';
import styled from 'styled-components';

import SmallNavigation from './SmallNavigation';
import Hamburger_Main from './Hamburger_Main';
import LargeNavigation from './LargeNavigation';

const MobileNavWrapper = styled.div``;
const DesktopNavWrapper = styled.div``;

const NavWrapper = styled.div`
	${MobileNavWrapper} {
		display: block;
	}
	${DesktopNavWrapper} {
		display: none;
	}
	@media screen and (min-width: 1370px) {  // Hardcoded for testing
		${MobileNavWrapper} {
			display: none;
		}
		${DesktopNavWrapper} {
			display: block;
		}
	}
`;

export default function MainNavigation({ landingPage }) {  // Fixed typo here
	return (
		<NavWrapper>
			<MobileNavWrapper>
				<SmallNavigation />
			</MobileNavWrapper>
			<DesktopNavWrapper>
				<LargeNavigation landingPage={landingPage} />
			</DesktopNavWrapper>
		</NavWrapper>
	);
}
